/* SignUp Component CSS */

/* General Styles */
.signupScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent; /* Set to transparent */
  font-family: var(--font-family-primary, 'Nunito', 'Varela Round', sans-serif);
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
}

/* Form Container */
.formContainer {
  background-color: transparent; /* Set to transparent */
  border-radius: var(--radius-lg, 12px);
  padding: 20px;
  box-shadow: none; /* Remove shadow for full transparency */
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

/* Form Header with integrated back button */
.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}

/* The title is now in the header */
.title {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Rubik', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
  text-align: center;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  flex: 1;
}

/* Back Button - Improved, accessible version */
.backButton {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: var(--text-primary, #FFFFFF);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  padding: 0;
}

.backButton:hover, .backButton:focus {
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

.backButton:active {
  transform: translateY(-50%) scale(0.95);
}

/* Create Account Text */
.createAccountText {
  font-size: 14px;
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  cursor: pointer;
  text-align: center;
  margin-top: 15px;
  text-decoration: none;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.createAccountText:hover {
  opacity: 1;
  color: #f9a8d4; /* pink-300 */
}

.linkText {
  color: #f9a8d4; /* pink-300 */
  text-decoration: underline;
}

/* Title and Subtitle Styles */
.titleContainer {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
}

.titleLogo {
  margin-bottom: 10px; /* Space below the logo */
  margin-top: 0; /* Remove top margin */
}

.mainTitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Rubik', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Animation for gradient shift */
@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.superSubtitle {
  margin-bottom: 15px; /* Space below the subtitle */
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  text-align: center;
}

/* Input Fields */
.signupScreen .inputField {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px; /* Increased space between input fields */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Match global style */
  border-radius: var(--radius-md, 8px);
  background: rgba(255, 255, 255, 0.05); /* Match global style */
  color: var(--text-primary, #FFFFFF);
  transition: var(--transition-normal, 0.3s ease);
  box-sizing: border-box;
  font-size: 1rem; /* Fixed font size instead of clamp to prevent jittering */
  height: 46px; /* Fixed height for inputs */
}

.signupScreen .inputField:focus {
  border-color: #f9a8d4; /* pink-300 */
  outline: none;
  box-shadow: 0 0 0 2px rgba(249, 168, 212, 0.2); /* pink-300 with opacity */
}

/* Button Group */
.buttonGroup {
  display: flex;
  gap: 10px;
  justify-content: space-between; /* Space between buttons */
  width: 100%;
}

/* Action Button with Dynamic Gradient */
.actionButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold, 600);
  color: #1a1a1a;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  border: none;
  border-radius: var(--radius-full, 9999px);
  cursor: pointer;
  transition: var(--transition-normal, 0.3s ease);
  min-height: 2.75rem;
  height: 44px; /* Fixed height for buttons */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.actionButton:hover {
  transform: translateY(-1px);
  filter: brightness(1.05);
  box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.15), 0 3px 5px -1px rgba(0, 0, 0, 0.1);
}

/* Secondary Button */
.actionButton.secondary {
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.actionButton.secondary:hover {
  background: var(--background-secondary, #1A1A1A);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.5);
}

/* Style description text */
.description {
  font-size: clamp(0.875rem, 2vw, 1rem);
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align: center;
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
}

/* Final phase styling */
.finalPhase {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
}

.mainTitle {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ff8a9b;
}

.description {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #f5f5f5;
  line-height: 1.5;
}

.nextSteps {
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  color: #bbb;
  line-height: 1.5;
  max-width: 300px;
}

/* Enhance the action button for the final phase */
.finalPhase .actionButton {
  background: linear-gradient(135deg, #ff6585 0%, #ff8a9b 100%);
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(255, 101, 133, 0.2);
}

.finalPhase .actionButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 101, 133, 0.3);
}

/* Error message styling */
.error {
  color: #f9a8d4; /* pink-300 */
  font-size: 0.875rem;
  margin: 0.5rem 0 1rem;
  text-align: center;
  min-height: 1.25rem; /* Fixed height for error messages */
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .formContainer {
    padding: 15px 10px;
    max-width: 100%;
  }
  
  .title {
    margin-bottom: 0;
    font-size: 1.5rem;
    /* Ensure the title doesn't overlap with the button */
    padding-right: 36px;
  }
  
  .formHeader {
    margin-bottom: 20px;
  }
  
  .backButton {
    width: 32px;
    height: 32px;
  }
  
  .signupScreen .inputField {
    padding: 10px;
    margin-bottom: 15px;
    height: 42px; /* Slightly smaller height on mobile */
  }
  
  .buttonGroup {
    flex-direction: column; /* Stack buttons on small screens */
    gap: 8px;
  }
  
  .actionButton {
    max-width: 100%; /* Full width on small screens */
    min-height: 2.5rem;
    height: 40px; /* Smaller buttons on mobile */
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
  }
  
  .description {
    margin-bottom: 1rem;
  }
}

/* Very small screens */
@media (max-width: 360px) {
  .formContainer {
    padding: 10px 5px;
  }
  
  .title {
    font-size: 1.25rem;
  }
  
  .signupScreen .inputField {
    padding: 8px;
    height: 40px;
  }
}

/* Add celebration particles styles */
.celebration-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
}

.particle {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  animation: floatParticle ease-in-out forwards;
}

@keyframes floatParticle {
  0% {
    transform: translateY(0) translateX(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100px) translateX(var(--random-x, 20px)) rotate(360deg);
    opacity: 0;
  }
}

/* Make content appear above particles */
.finalPhase h2, 
.finalPhase p, 
.finalPhase button {
  position: relative;
  z-index: 1;
} 