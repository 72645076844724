/* ==========================================================
   CompanionSelection CSS - Pokémon-inspired Theme with Brand Aesthetics
   ========================================================== */

/* ============= Import Fonts ============= */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&display=swap');

/* ============= Tailwind CSS Directives ============= */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ============= CSS Reset for Consistency ============= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  background-color: var(--background-primary, #121212);
  color: var(--text-primary, #ffffff);
  overflow-x: hidden;
  overflow-y: auto;
}

/* ============= Root Variables ============= */
:root {
  --text-primary: #ffffff;
  --text-secondary: #FFC4BC;
  --text-tertiary: #FFAB9C;
  --background-primary: #121212;
  --background-secondary: #1a1b26;
  --background-soft: #1e1e1e;
  --accent-primary: #2a2b36;
  --accent-secondary: #32333e;
  --accent-highlight: #FFC4BC;
  --border-primary: #2a2b36;
  
  /* Brand Gradients */
  --primary-gradient: linear-gradient(135deg, #FFC4BC, #FFAB9C);
  --pokemon-gradient: linear-gradient(135deg, #FFAB9C, #FF8A7A);
  --card-gradient: linear-gradient(to bottom, #2a2b36, #222531);
  
  /* Element Gradients */
  --ghost-gradient: linear-gradient(135deg, #9D91FF, #7F6FFF);
  --fire-gradient: linear-gradient(135deg, #FFA359, #FF7B1F);
  --water-gradient: linear-gradient(135deg, #5db9ff, #3d8ad8);
  --electric-gradient: linear-gradient(135deg, #FFE17A, #FFD42A);
  --grass-gradient: linear-gradient(135deg, #8cd46f, #5bb73d);
  --ash-gradient: linear-gradient(135deg, #FF9E8F, #FF7D6A);
  
  /* Shadows and Effects */
  --card-shadow: 0 10px 30px rgba(0, 0, 0, 0.25), 
                 0 15px 15px rgba(0, 0, 0, 0.15),
                 0 5px 5px rgba(0, 0, 0, 0.1);
  --card-glow: 0 0 20px rgba(255, 196, 188, 0.3);
  --text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  
  /* Font sizes that scale better */
  --font-size-xl: clamp(1.5rem, 4vw, 1.75rem);
  --font-size-lg: clamp(1.25rem, 3vw, 1.5rem);
  --font-size-md: clamp(1rem, 2.5vw, 1.125rem);
  --font-size-sm: clamp(0.875rem, 2vw, 1rem);
  --font-size-xs: clamp(0.75rem, 1.8vw, 0.875rem);
}

/* ============= CompanionSelection Styles ============= */
.companion-selection-overlay {
  position: fixed;
  inset: 0;
  background: linear-gradient(135deg, rgba(26, 27, 38, 0.97), rgba(18, 18, 24, 0.98));
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  touch-action: pan-y;
}

/* Disable text selection on mobile */
.companion-selection-open {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.companion-selection-container {
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.5rem;
  max-width: 100%;
  box-sizing: border-box;
}

/* Animated particle background */
.particle-container {
  position: absolute;
  inset: 0;
  z-index: -1;
  overflow: hidden;
}

.floating-particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--accent-highlight);
  border-radius: 50%;
  opacity: 0.3;
  animation: floatAnimation cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
  filter: blur(1px);
}

.floating-particle:nth-child(odd) {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 171, 156, 0.25);
}

.floating-particle:nth-child(3n) {
  width: 3px;
  height: 3px;
  background-color: rgba(255, 158, 143, 0.35);
}

.companion-selection-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30vh;
  background: linear-gradient(to bottom, 
                            var(--accent-highlight) 0%, 
                            rgba(255, 196, 188, 0.1) 20%, 
                            rgba(255, 171, 156, 0) 100%);
  opacity: 0.05;
  pointer-events: none;
  z-index: -1;
}

.companion-selection-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 1.5rem;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
}

/* Professor Section */
.professor-section {
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.professor-avatar {
  font-size: 4rem;
  filter: drop-shadow(0 0 15px rgba(255, 196, 188, 0.4));
  animation: floatAnimation 3s ease-in-out infinite;
}

.dialog-box {
  background: linear-gradient(135deg, rgba(42, 43, 54, 0.9), rgba(50, 51, 62, 0.85));
  border-radius: 24px;
  padding: 1.5rem;
  border: 2px solid rgba(255, 196, 188, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.dialog-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(to right, 
              rgba(255, 196, 188, 0) 0%, 
              rgba(255, 171, 156, 0.8) 50%, 
              rgba(255, 196, 188, 0) 100%);
}

.dialog-text {
  color: #fff;
  font-size: var(--font-size-lg);
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: 0.02em;
  margin: 0;
  animation: fadeIn 0.5s ease-out forwards;
  text-shadow: var(--text-shadow);
  min-height: 3.2em;
}

.dialog-text.typing {
  white-space: pre-wrap;
  position: relative;
}

/* Replace the after with a span for better control */
.typing-cursor {
  display: inline-block;
  animation: blink 1s step-start infinite;
  opacity: 0.7;
}

/* Companion Thumbnails - updated positioning */
.companion-thumbnails {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  padding: 0.8rem 0.6rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.companion-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.4rem;
  padding: 0.6rem 0.4rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 70px;
  flex: 1;
  max-width: 90px;
}

.companion-thumbnail.active {
  border-color: #000;
  background: linear-gradient(135deg, rgba(255, 196, 188, 0.7), rgba(255, 171, 156, 0.6));
  box-shadow: 0 0 12px rgba(255, 196, 188, 0.4);
  transform: translateY(-3px) scale(1.05);
}

.companion-thumbnail.active .thumbnail-name {
  background: rgba(255, 255, 255, 0.95);
  color: #000;
  font-weight: 700;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.thumbnail-name {
  font-size: 0.8rem;
  font-weight: 600;
  color: #1a1a1a;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.15rem 0.25rem;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
  line-height: 1;
}

.thumbnail-icon {
  font-size: 2rem;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 36px;
  width: 100%;
  text-align: center;
}

/* Compact dialog for selection phase */
.compact-dialog {
  background: linear-gradient(135deg, rgba(42, 43, 54, 0.8), rgba(50, 51, 62, 0.75));
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(255, 196, 188, 0.25);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  position: relative;
}

.dialog-text-compact {
  color: #fff;
  font-size: var(--font-size-md);
  line-height: 1.5;
  margin: 0;
  text-shadow: var(--text-shadow);
  min-height: 3em;
}

/* Pokemon-style Carousel - more compact layout */
.companion-carousel {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.carousel-container {
  display: flex;
  position: relative;
  min-height: 400px; /* Further reduced height */
}

/* Adjust companion card layout */
.companion-card {
  gap: 0.5rem; /* Reduced gap to fit more content */
  min-height: 400px; /* Further reduced height */
  padding: 1.5rem; /* Reduced padding */
}

/* Adjust spacing for all card elements to be more compact */
.card-header {
  padding-bottom: 0.5rem;
  margin-bottom: 0.4rem;
}

/* Companion icon container - reduce container, maximize emoji size */
.companion-icon-container {
  width: 120px; /* Smaller container */
  height: 120px;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: visible;
  padding: 0; /* Remove any padding */
}

.companion-icon {
  font-size: 6.5rem; /* Actually make emoji bigger, not just the container */
  position: relative;
  z-index: 5;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4));
  transform: scale(1.2); /* Scale up the emoji */
}

.companion-description {
  margin-bottom: 0.75rem;
  line-height: 1.4;
  font-size: 1rem;
}

.companion-traits {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.companion-stats {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.25rem;
}

.stat-bars {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Additional responsive styles */
@media (max-width: 768px) {
  .carousel-container {
    min-height: 380px;
  }
  
  .companion-card {
    min-height: 380px;
    padding: 1rem;
  }
  
  .companion-icon-container {
    width: 110px;
    height: 110px;
    margin-bottom: 0.75rem;
  }
  
  .companion-icon {
    font-size: 6rem;
    transform: scale(1.15);
  }
}

@media (max-width: 480px) {
  .companion-selection-content {
    padding: 0.75rem;
    gap: 0.75rem;
    padding-top: 0.5rem; /* Reduced top padding since we removed the wizard */
  }
  
  .compact-dialog {
    padding: 0.75rem;
  }
  
  .dialog-text-compact {
    font-size: var(--font-size-xs);
  }
  
  .carousel-container {
    min-height: 360px;
  }

  .companion-card {
    min-height: 360px;
    padding: 0.75rem;
    gap: 0.4rem;
  }
}

@media (max-width: 375px) {
  .carousel-container {
    min-height: 340px;
  }

  .companion-card {
    min-height: 340px;
    padding: 0.625rem;
    gap: 0.3rem;
  }
  
  .card-header {
    padding-bottom: 0.375rem;
    margin-bottom: 0.3rem;
  }
  
  .companion-icon-container {
    width: 80px; 
    height: 80px;
  }
  
  .companion-icon {
    font-size: 4.5rem;
    transform: scale(1.05);
  }
  
  .companion-description {
    font-size: 0.75rem;
    margin-bottom: 0.375rem;
    line-height: 1.25;
  }
  
  .companion-traits {
    margin-bottom: 0.3rem;
    gap: 0.4rem;
  }
  
  .stat-bar-container {
    grid-template-columns: 80px 1fr 30px;
    gap: 0.25rem;
  }
  
  .stat-name, .stat-value {
    font-size: 0.7rem;
  }
  
  .stat-bar-bg {
    height: 7px;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes floatAnimation {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* Additional responsive styles */
@media (max-width: 768px) {
  .companion-thumbnails {
    gap: 0.5rem;
  }
  
  .companion-thumbnail {
    padding: 0.5rem 0.4rem;
    gap: 0.4rem;
  }
  
  .thumbnail-icon {
    font-size: 1.8rem;
    min-height: 32px;
  }
  
  .thumbnail-name {
    font-size: 0.7rem;
    padding: 0.12rem 0.2rem;
  }
  
  .carousel-container {
    min-height: 450px;
  }
  
  .companion-card {
    min-height: 450px;
    padding: 1.25rem;
  }
  
  .ritual-animation {
    height: 250px;
  }
  
  .ritual-companion-icon {
    font-size: 5rem;
  }
  
  .companion-selection-content {
    padding: 1rem;
    gap: 1rem;
  }

  .professor-section {
    padding: 0.75rem;
    gap: 1rem;
  }

  .professor-avatar {
    font-size: 3.5rem;
  }

  .dialog-box {
    padding: 1.25rem;
    border-radius: 20px;
  }

  .dialog-text {
    font-size: var(--font-size-md);
    min-height: 4.5em; /* Ensure consistent height to prevent layout jumps */
  }

  .companion-icon {
    font-size: 4rem;
  }

  .companion-name {
    font-size: 1.75rem;
  }

  .companion-description {
    font-size: 1rem;
  }

  .trait-tag {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }
  
  .companion-selection-container .btn-primary,
  .companion-selection-container .btn-accent {
    padding: 0.75rem 1.5rem;
    font-size: var(--font-size-sm);
  }
}

@media (max-width: 480px) {
  .companion-thumbnails {
    padding: 0.7rem 0.5rem;
    gap: 0.4rem;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .companion-thumbnail {
    padding: 0.4rem 0.3rem;
    min-width: 60px;
    max-width: 75px;
    gap: 0.3rem;
  }
  
  .thumbnail-icon {
    font-size: 1.7rem;
    min-height: 30px;
  }
  
  .thumbnail-name {
    font-size: 0.65rem;
    padding: 0.1rem 0.15rem;
  }
  
  .carousel-container {
    min-height: 420px;
  }

  .companion-card {
    min-height: 420px;
    padding: 1rem;
    gap: 0.5rem;
  }
  
  .companion-stats {
    gap: 0.5rem;
  }
  
  .stat-bars {
    gap: 0.4rem;
  }
  
  .ritual-animation {
    height: 200px;
  }
  
  .ritual-companion-icon {
    font-size: 4rem;
  }
  
  .professor-avatar {
    font-size: 3rem;
  }
  
  .dialog-box {
    padding: 1rem;
    border-radius: 16px;
  }
  
  .dialog-text {
    font-size: var(--font-size-sm);
    line-height: 1.5;
  }
  
  .companion-icon-container {
    width: 90px;
    height: 90px;
  }
  
  .companion-icon {
    font-size: 4rem;
  }
  
  .companion-name {
    font-size: 1.4rem;
  }
  
  .companion-description {
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
  }
  
  .trait-tag {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  }
  
  .stat-bar-container {
    grid-template-columns: 70px 1fr 35px;
    gap: 0.4rem;
  }
  
  .stat-name, .stat-value {
    font-size: 0.85rem;
  }
  
  .stat-bar-bg {
    height: 10px;
  }
  
  .companion-selection-container .btn-primary,
  .companion-selection-container .btn-accent {
    padding: 0.625rem 1.25rem;
    font-size: var(--font-size-xs);
  }
  
  /* When thumbnails are at bottom, ensure button is above */
  .summon-button, .confirm-button {
    position: relative;
    z-index: 110;
  }
}

/* For very small screens, reduce the card height and adjust content */
@media (max-width: 375px) {
  .carousel-container {
    min-height: 380px;
  }

  .companion-card {
    min-height: 380px;
    padding: 0.75rem;
    gap: 0.35rem;
  }
  
  .card-header {
    padding-bottom: 0.5rem;
    margin-bottom: 0.4rem;
  }
  
  .companion-icon-container {
    width: 80px; 
    height: 80px;
  }
  
  .companion-icon {
    font-size: 4.5rem;
    transform: scale(1.05);
  }
  
  .companion-name {
    font-size: 1.2rem;
  }
  
  .companion-level {
    padding: 0.25rem 0.45rem;
    font-size: 0.75rem;
  }
  
  .companion-description {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    line-height: 1.3;
  }
  
  .companion-traits {
    margin-bottom: 0.5rem;
  }
  
  .trait-tag {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }
  
  .stat-bar-container {
    grid-template-columns: 80px 1fr 30px;
    gap: 0.3rem;
  }
  
  .stat-name, .stat-value {
    font-size: 0.75rem;
  }
  
  .stat-bar-bg {
    height: 8px;
  }
  
  .companion-thumbnails {
    margin-top: 0.75rem;
  }
  
  .companion-thumbnail {
    padding: 0.35rem 0.25rem;
    min-width: 50px;
    max-width: 65px;
  }
  
  .thumbnail-icon {
    font-size: 1.6rem;
    min-height: 28px;
  }
  
  .thumbnail-name {
    font-size: 0.6rem;
    padding: 0.08rem 0.12rem;
  }
  
  .companion-selection-container .btn-primary,
  .companion-selection-container .btn-accent {
    padding: 0.5rem 1rem;
    font-size: var(--font-size-xs);
    margin-bottom: 70px;
  }
  
  .button-icon {
    font-size: 1rem;
  }
}

/* Fix the selection buttons positioning to be fixed at bottom of screen on small devices */
@media (max-height: 700px) {
  .companion-selection-container {
    padding-bottom: 70px;
  }
  
  .companion-thumbnails {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(26, 27, 38, 0.95), rgba(26, 27, 38, 0.8), rgba(26, 27, 38, 0));
    padding: 12px 5px 8px;
    margin: 0;
    z-index: 100;
  }
}

/* Ritual Summoning styles */
.ritual-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  padding: 1rem;
}

.ritual-progress-container {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ritual-progress-bar {
  height: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), inset 0 1px 3px rgba(0, 0, 0, 0.3);
}

.ritual-progress-fill {
  height: 100%;
  background: linear-gradient(to right, #FFC4BC, #FF9E8F);
  border-radius: 10px;
}

.ritual-percentage {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--text-secondary);
  text-align: right;
}

.ritual-animation {
  width: 100%;
  height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ritual-companion-icon {
  font-size: 5rem;
  z-index: 2;
  filter: drop-shadow(0 0 20px rgba(255, 196, 188, 0.6));
}

.ritual-particles {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.ritual-particle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Stat bar container styles */
.stat-bar-container {
  display: grid;
  grid-template-columns: 120px 1fr 40px;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  white-space: nowrap;
}

.stat-name {
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stat-bar-bg {
  height: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  overflow: hidden;
}

.stat-bar-fill {
  height: 100%;
  background: linear-gradient(to right, #FFC4BC, #FF9E8F);
  border-radius: 6px;
}

.stat-value {
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--accent-highlight);
  text-align: left;
}

/* Pokemon-style card styles (updated) */
.pokemon-card {
  flex: 0 0 100%;
  position: relative;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  background: var(--card-gradient);
  border: 2px solid rgba(255, 196, 188, 0.1);
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  will-change: transform;
  transform-style: preserve-3d;
  cursor: pointer;
}

.pokemon-card::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: linear-gradient(135deg, 
              rgba(255, 255, 255, 0.1) 0%, 
              rgba(255, 255, 255, 0.05) 20%, 
              rgba(255, 255, 255, 0) 80%);
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.pokemon-card.selected {
  border: 2px solid var(--accent-highlight);
  box-shadow: 0 0 30px rgba(255, 196, 188, 0.4);
  transform: translateY(-8px) scale(1.02);
  position: relative;
  z-index: 20;
}

.card-shine {
  position: absolute;
  inset: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.5;
  border-radius: 20px;
  mix-blend-mode: soft-light;
}

.companion-level {
  background: var(--accent-highlight);
  color: #1a1b26;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  font-size: 0.8rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.companion-name {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-shadow: var(--text-shadow);
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(to right, #fff, #ffedea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.icon-glow {
  position: absolute;
  width: 140%;
  height: 140%;
  left: -20%;
  top: -20%;
  border-radius: 50%;
  background: radial-gradient(circle, 
              rgba(255, 196, 188, 0.5) 0%, 
              rgba(255, 171, 156, 0.3) 40%,
              rgba(255, 171, 156, 0) 70%);
  filter: blur(15px);
  opacity: 0.9;
  animation: pulseGlow 3s ease-in-out infinite;
  z-index: 1;
}

.trait-tag {
  background: rgba(255, 196, 188, 0.15);
  color: var(--text-secondary);
  padding: 0.4rem 0.8rem;
  border-radius: 999px;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  font-weight: 500;
  border: 1px solid rgba(255, 196, 188, 0.3);
  transition: all 0.2s ease;
  backdrop-filter: blur(5px);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.selection-indicator {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
  animation: pulse 2s ease-in-out infinite;
  z-index: 10;
  pointer-events: none;
}

/* Fix for selection and active indicators to prevent overlap */
.selection-indicator, 
.active-indicator {
  position: absolute;
  z-index: 10;
  pointer-events: none;
}

.selection-indicator {
  top: 8px;
  left: 8px;
}

.active-indicator {
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 196, 188, 0.2);
  color: #ff9494;
  border-radius: 999px;
  font-size: 0.75rem;
  padding: 0.15rem 0.75rem;
  font-weight: 500;
  white-space: nowrap;
  min-width: 60px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Animations that were required for other elements */
@keyframes pulse {
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.1); opacity: 1; }
}

@keyframes pulseGlow {
  0%, 100% { opacity: 0.6; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.1); }
}

/* Additional responsive styles */
@media (max-width: 768px) {
  .carousel-container {
    min-height: 380px;
  }
  
  .companion-card {
    min-height: 380px;
    padding: 1rem;
  }
  
  .companion-icon-container {
    width: 110px;
    height: 110px;
    margin-bottom: 0.75rem;
  }
  
  .companion-icon {
    font-size: 6rem;
    transform: scale(1.15);
  }
  
  .stat-bar-container {
    grid-template-columns: 110px 1fr 40px;
  }
}

@media (max-width: 480px) {
  .companion-selection-content {
    padding: 0.75rem;
    gap: 0.75rem;
    padding-top: 0.5rem; /* Reduced top padding since we removed the wizard */
  }
  
  .compact-dialog {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .dialog-text-compact {
    font-size: var(--font-size-xs);
  }
  
  .carousel-container {
    min-height: 360px;
  }

  .companion-card {
    min-height: 360px;
    padding: 0.75rem;
    gap: 0.4rem;
  }
  
  .stat-bar-container {
    grid-template-columns: 90px 1fr 35px;
  }
  
  /* Add space for the thumbnails at bottom */
  .btn-primary, .btn-secondary, .btn-tertiary, .btn-accent {
    margin-bottom: 60px;
    position: relative;
    z-index: 110; /* Ensure buttons appear above thumbnails */
  }
}

/* Button overrides for CompanionSelection context */
.companion-selection-container .btn-primary,
.companion-selection-container .btn-secondary,
.companion-selection-container .btn-tertiary, 
.companion-selection-container .btn-accent {
  color: #1a1a1a; /* Dark text color for contrast with light button backgrounds */
  font-weight: 600;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  justify-content: center;
  z-index: 110; /* Ensure button appears above thumbnails */
  margin-bottom: 80px; /* More space from bottom to clear thumbnails */
  padding: 0.875rem 1.75rem;
  font-size: var(--font-size-md);
  position: relative; /* Ensure z-index works */
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  will-change: transform;
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight on mobile */
}

.companion-selection-container .btn-primary {
  background: var(--gradient-button-1);
}

.companion-selection-container .btn-accent {
  background: var(--gradient-button-4);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  margin-top: 1rem;
}

@media (max-width: 375px) {
  .companion-thumbnails {
    padding: 0.6rem 0.4rem;
    gap: 0.35rem;
  }
  
  .companion-thumbnail {
    padding: 0.4rem 0.5rem;
    min-width: 50px;
    max-width: 65px;
  }
  
  .thumbnail-icon {
    font-size: 1.5rem;
    min-height: 28px;
  }
  
  .thumbnail-name {
    font-size: 0.7rem;
  }
}

/* Add a subtle pulsing effect to indicate clickability */
.pokemon-card:not(.selected)::after {
  content: 'Click to select';
  position: absolute;
  top: 10px; /* Move to top instead of bottom */
  right: 10px; /* Position in the corner */
  transform: none; /* Remove the translateX */
  left: auto; /* Reset left position */
  background: rgba(0, 0, 0, 0.6); /* Darker background for visibility */
  color: var(--text-secondary);
  padding: 0.3rem 0.6rem;
  border-radius: 20px;
  font-size: 0.75rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: pulseOpacity 2s ease-in-out infinite;
  z-index: 10;
  border: 1px solid rgba(255, 196, 188, 0.3);
}

.pokemon-card:hover:not(.selected)::after {
  opacity: 1;
}

/* Additional animation for the clickability hint */
@keyframes pulseOpacity {
  0%, 100% { opacity: 0.7; }
  50% { opacity: 1; }
}

/* Better desktop responsiveness - update existing media query */
@media (min-width: 1024px) {
  .companion-selection-content {
    max-width: 800px;
    padding: 2rem;
  }
  
  .carousel-container {
    min-height: 500px;
  }
  
  .companion-card {
    min-height: 500px;
    padding: 2rem;
    max-width: 85%;
    margin: 0 auto;
  }
  
  .stat-bar-container {
    grid-template-columns: 150px 1fr 40px;
  }
  
  .stat-name, .stat-value {
    font-size: 1rem;
  }
  
  .stat-bar-bg {
    height: 12px;
  }
  
  .companion-name {
    font-size: 1.8rem;
  }
  
  .companion-description {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  
  .trait-tag {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
  
  .companion-icon-container {
    width: 140px;
    height: 140px;
  }
  
  .companion-icon {
    font-size: 7.5rem;
    transform: scale(1.25);
  }
  
  /* Make the click to select more subtle on desktop */
  .pokemon-card:not(.selected)::after {
    top: 15px;
    right: 15px;
    font-size: 0.8rem;
    padding: 0.3rem 0.7rem;
  }
}

/* Hide "Click to select" when card is selected */
.pokemon-card.selected::after {
  display: none;
}

/* Desktop hover effects */
@media (hover: hover) {
  .pokemon-card:hover:not(.selected) {
    transform: translateY(-5px) scale(1.01);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 196, 188, 0.3);
  }
  
  .companion-thumbnail:hover:not(.active) {
    transform: translateY(-2px);
    background: rgba(50, 51, 62, 0.8);
    border-color: rgba(255, 196, 188, 0.3);
  }
}

/* Fix responsive issues on medium-sized screens */
@media (max-width: 768px) {
  .companion-icon-container {
    width: 110px;
    height: 110px;
    margin-bottom: 0.75rem;
  }
  
  .companion-icon {
    font-size: 6rem;
    transform: scale(1.15);
  }
  
  .stat-bar-container {
    grid-template-columns: 110px 1fr 40px;
  }
}

/* Fix responsive issues on small screens */
@media (max-width: 480px) {
  .companion-icon-container {
    width: 90px; 
    height: 90px;
    margin-bottom: 0.5rem;
  }
  
  .companion-icon {
    font-size: 5rem;
    transform: scale(1.1);
  }
  
  .stat-bar-container {
    grid-template-columns: 90px 1fr 35px;
  }
  
  /* Hide the "Click to select" prompt on mobile as it's obvious with touch */
  .pokemon-card:not(.selected)::after {
    display: none;
  }
  
  /* Adjust sizing of indicators on small screens */
  .selection-indicator {
    top: 5px;
    left: 5px;
    width: 18px;
    height: 18px;
  }
  
  .active-indicator {
    top: 5px;
    right: 5px;
    min-width: 50px;
    font-size: 0.65rem;
    padding: 2px 6px;
  }
}

/* For very small screens */
@media (max-width: 375px) {
  .companion-icon-container {
    width: 80px; 
    height: 80px;
  }
  
  .companion-icon {
    font-size: 4.5rem;
    transform: scale(1.05);
  }
  
  .stat-bar-container {
    grid-template-columns: 80px 1fr 30px;
  }
}

/* Make the summon button more prominent when enabled */
.summon-button {
  background: var(--gradient-button-1);
  margin-top: 1.5rem;
  transition: all 0.3s ease;
  transform-origin: center;
  padding: 0.85rem 2.5rem;
  border: 2px solid transparent;
  position: relative;
  z-index: 110;
}

.summon-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background: #555;
  color: rgba(255, 255, 255, 0.6);
  transform: none;
  border: 2px dashed rgba(255, 196, 188, 0.3);
  box-shadow: none;
}

.summon-button:disabled::after {
  content: 'Select a companion first';
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.85rem;
  color: var(--text-secondary);
  white-space: nowrap;
  animation: pulseOpacity 2s ease-in-out infinite;
}

.summon-button:not(:disabled) {
  animation: pulseButton 2s infinite alternate;
}

@keyframes pulseButton {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(255, 196, 188, 0.5);
  }
}

/* Make the confirmation button match the styling */
.confirm-button {
  background: var(--gradient-button-4);
  margin-top: 1.5rem;
  transition: all 0.3s ease;
  transform-origin: center;
  padding: 0.85rem 2.5rem;
  position: relative;
  z-index: 110;
}

/* Adjust for mobile */
@media (max-width: 480px) {
  .summon-button, .confirm-button {
    padding: 0.7rem 2rem;
  }
  
  .summon-button:disabled::after {
    bottom: -22px;
    font-size: 0.75rem;
  }
}

@media (max-width: 375px) {
  .summon-button, .confirm-button {
    padding: 0.6rem 1.75rem;
    font-size: 0.9rem;
  }
}

/* Add back the visible selected indicator */
.pokemon-card.selected::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 18px;
  background: linear-gradient(135deg, 
              rgba(255, 196, 188, 0.2) 0%, 
              rgba(255, 171, 156, 0.1) 20%, 
              rgba(255, 171, 156, 0) 80%);
  z-index: 3;
  pointer-events: none;
  animation: pulse 3s ease-in-out infinite;
}

/* Add ceremonial loading animation styles */
.journey-transition {
  position: fixed;
  inset: 0;
  background: linear-gradient(135deg, rgba(26, 27, 38, 0.99), rgba(18, 18, 24, 1));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
}

.journey-transition.active {
  opacity: 1;
  pointer-events: all;
}

.journey-animation {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.journey-companion {
  font-size: 6rem;
  position: relative;
  z-index: 5;
  filter: drop-shadow(0 0 25px rgba(255, 196, 188, 0.6));
  animation: floatAndPulse 3s ease-in-out infinite;
}

.journey-glow {
  position: absolute;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background: radial-gradient(circle, 
              rgba(255, 196, 188, 0.3) 0%, 
              rgba(255, 171, 156, 0.15) 40%,
              rgba(255, 171, 156, 0) 70%);
  filter: blur(20px);
  opacity: 0.8;
  animation: pulseLarge 3s ease-in-out infinite;
}

.journey-text {
  font-size: clamp(1.2rem, 5vw, 2rem);
  margin: 1.5rem 0;
  padding: 0 1rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.journey-loading {
  display: flex;
  gap: 0.7rem;
  margin: 1rem 0;
  justify-content: center;
  width: 100%;
}

.journey-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--accent-highlight);
  animation: journeyDotPulse 1.5s ease-in-out infinite;
}

.journey-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.journey-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.journey-particles {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.journey-particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--accent-highlight);
  border-radius: 50%;
  opacity: 0.6;
  animation: particleFloat 10s linear infinite;
}

.journey-particle:nth-child(odd) {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 171, 156, 0.6);
}

.journey-particle:nth-child(3n) {
  width: 3px;
  height: 3px;
  background-color: rgba(255, 196, 188, 0.7);
}

@keyframes floatAndPulse {
  0%, 100% { 
    transform: translateY(0) scale(1); 
    filter: drop-shadow(0 0 25px rgba(255, 196, 188, 0.6));
  }
  50% { 
    transform: translateY(-15px) scale(1.1); 
    filter: drop-shadow(0 0 40px rgba(255, 196, 188, 0.8));
  }
}

@keyframes pulseLarge {
  0%, 100% { 
    opacity: 0.5; 
    transform: scale(0.9);
  }
  50% { 
    opacity: 0.8; 
    transform: scale(1.1);
  }
}

@keyframes journeyDotPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
}

@keyframes particleFloat {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    transform: translate(var(--x, 100px), var(--y, 100px));
    opacity: 0;
  }
}

/* Media queries for responsive loading animation */
@media (max-width: 768px) {
  .journey-animation {
    max-width: 250px;
    height: 250px;
  }
  
  .journey-companion {
    font-size: 5rem;
  }
  
  .journey-text {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .journey-animation {
    max-width: 200px;
    height: 200px;
  }
  
  .journey-companion {
    font-size: 4rem;
  }
  
  .journey-text {
    font-size: 1.1rem;
  }
}

/* Safari/iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .companion-selection-container {
    min-height: -webkit-fill-available;
    padding-bottom: env(safe-area-inset-bottom, 0.5rem);
    padding-top: env(safe-area-inset-top, 0.5rem);
  }
  
  .dialog-box, 
  .companion-card, 
  .companion-thumbnails,
  .btn-primary, 
  .btn-secondary, 
  .btn-tertiary, 
  .btn-accent {
    transform: translateZ(0);
  }
}

/* Fix for iPhone X and newer notch */
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
       screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
       screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3),
       screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3),
       screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .companion-selection-overlay {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .companion-selection-container {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* Ensure elements are accessible for touch interactions on smaller screens */
@media (hover: none) and (pointer: coarse) {
  .companion-thumbnail,
  .btn-primary, 
  .btn-secondary, 
  .btn-tertiary, 
  .btn-accent {
    min-height: 44px; /* Apple's recommendation for touch targets */
  }
  
  .companion-thumbnails {
    padding: 0.5rem;
  }
}

/* Prevent text from being too small */
@media screen and (max-width: 320px) {
  html {
    font-size: 14px;
  }
  
  .companion-selection-content {
    padding: 0.75rem;
  }
  
  .professor-avatar {
    font-size: 2.5rem;
  }
  
  .dialog-text {
    font-size: var(--font-size-xs);
  }
}

/* Smooth animations for all transitions */
* {
  transition-property: color, background-color, border-color, opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

/* Add smooth touch interactions for mobile */
@media (hover: none) and (pointer: coarse) {
  .companion-thumbnail,
  .companion-card,
  .companion-selection-container .btn-primary,
  .companion-selection-container .btn-accent {
    transition: transform 0.2s ease;
    will-change: transform;
  }
  
  .companion-thumbnail:active,
  .companion-card:active,
  .companion-selection-container .btn-primary:active,
  .companion-selection-container .btn-accent:active {
    transform: scale(0.97);
  }
}

/* For iOS momentum scrolling and better native feel */
html.companion-selection-open,
body.companion-selection-open {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

/* Improve hardware acceleration on mobile */
.companion-selection-overlay,
.companion-card,
.companion-thumbnail,
.btn-primary,
.btn-accent,
.journey-transition {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

/* Fix for iOS button highlights */
.companion-selection-overlay button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}

/* Special iOS-specific overrides */
@supports (-webkit-touch-callout: none) {
  .companion-selection-container {
    /* Fix for iOS height issue */
    height: -webkit-fill-available;
  }
  
  /* Improve touch response on iOS */
  .companion-thumbnail, 
  .companion-card,
  .btn-primary,
  .btn-accent {
    cursor: pointer;
    touch-action: manipulation;
  }
}

/* Ensure touch targets are large enough on mobile */
@media (max-width: 768px) {
  .companion-thumbnail,
  .btn-primary,
  .btn-accent {
    min-height: 44px; /* Apple's recommended minimum touch target size */
    min-width: 44px;
  }
  
  /* Reduce animation complexity on lower-end mobile devices */
  .floating-particle {
    animation-duration: 15s !important; /* Standardize for performance */
    opacity: 0.2;
  }
  
  /* Optimize rendering performance */
  .particle-container {
    will-change: opacity;
    transform: translateZ(0);
  }
} 